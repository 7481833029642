import { ListField } from 'models/ListSettings';

type Column = {
  field: string;
  visible?: boolean;
  selected?: boolean;
};

type Stats = {
  total: number;
  selected: number;
};

export class TableManager {
  private table: string;

  public columns: ListField[];

  public stats: Stats = { selected: 0, total: 0 };

  constructor(table: string, columns: ListField[]) {
    this.table = table;
    this.setup(columns);
  }

  protected setup(columns: ListField[]) {
    const storage = this.tryLoadFromStorage();

    if (!storage) {
      this.columns = columns;
    } else {
      const columnsMap = new Map(columns?.map((c) => [c.field, c]));
      for (const storedColumn of storage) {
        const column = columnsMap.get(storedColumn.field);
        if (!column || column.disableControl) continue;

        column.selected = storedColumn.selected;
        column.visible = storedColumn.selected;
      }
      this.columns = Array.from(columnsMap.values());
    }

    this.calculateStats();
  }

  private calculateStats() {
    this.stats = this.columns.reduce(
      (acc, current) => {
        if (current.disableControl) return acc;
        acc.total += 1;
        if (current.selected) acc.selected += 1;
        return acc;
      },
      {
        selected: 0,
        total: 0,
      }
    );
  }

  private tryLoadFromStorage() {
    try {
      const storage = localStorage.getItem(this.table);
      if (!storage) return undefined;
      return JSON.parse(storage) as Column[];
    } catch {
      //
    }
    return undefined;
  }

  private trySaveToStorage() {
    try {
      const forSave = this.columns.map(
        (x) =>
          ({
            visible: x.visible,
            field: x.field,
            selected: x.selected,
          }) satisfies Column
      );
      localStorage.setItem(this.table, JSON.stringify(forSave));
    } catch {
      console.error(`Could not save table  ${this.table}`);
    }
  }

  protected onColumnVisibilityChange(event: Event, item: ListField) {
    const checkbox = event.target as HTMLInputElement;
    const c = this.columns.find((x) => x.field === item.field);
    if (!c) {
      console.error(`Unable to set column visibility for ${item?.field}`);
      return;
    }

    c.selected = checkbox.checked;
    c.visible = checkbox.checked;
    this.trySaveToStorage();
    this.calculateStats();
  }
}
